import React from 'react';
import { Link } from 'react-router-dom';

import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import {
  styled,
  AppBar,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Box,
  IconButton,
} from '@mui/material';

import { DrawerProps as HeaderProps } from 'client/components/Layout/Layout';
import { ReactComponent as LogoIcon } from 'client/components/Logo/logo.svg';
import { RoutesEnum } from 'client/enums/routes.enum';
import { useAuthContext } from 'client/hooks/useAuthContext';
import { GetUserDisplayName } from 'client/utils/get-user-display-name';

import useColorContext from '../../context/color';
import { useLogout } from '../../hooks/useLogout';
import { ThemeToggler } from '../ThemeToggler/ThemeToggler';

const HeaderAppBar = styled(AppBar)(() => ({
  paddingTop: 14,
  paddingRight: 32,
  paddingBottom: 14,
  paddingLeft: 32,
  background: 'none',
  boxShadow: 'none',
  flexDirection: 'row',
  justifyContent: 'flex-end',
}));

const HeaderButton = styled(Button)(({ theme }) => ({
  color: theme.typography.body2.color,
  fontSize: theme.typography.body2.fontSize,
  textTransform: 'none',
  '& .MuiButton-endIcon': {
    marginLeft: 2,
  },
}));

const HeaderMobileDrawer = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      marginRight: 20,
    },
  },
}));
const HeaderSwitchContainer = styled(Box)(() => ({
  marginLeft: 10,
  display: 'flex',
  alignItems: 'center',
}));

const HeaderAvatar = styled(Avatar)(() => ({
  height: 30,
  width: 30,
  marginRight: 4,
}));

export const Header: React.FC<HeaderProps> = (props) => {
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [showAccountMenu, setShowAccountMenu] = React.useState<boolean>(false);
  const [checked, setChecked] = React.useState(true);

  const { user } = useAuthContext();
  const { logout } = useLogout();
  const { isOpen, toggleDrawer } = props;

  const handleMenuClose = () => {
    setAnchorMenu(null);
    setShowAccountMenu(false);
  };

  const { toggleColorMode } = useColorContext();

  const handleChange = (checked: boolean) => {
    toggleColorMode();
    setChecked(checked);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
  };


  const baseUrl = process.env.REACT_APP_PROFILE_BASE_URL || "https://ultravioletsim.s3.ap-southeast-2.amazonaws.com/users/undefined/";
  const profileImageUrl = user.photo ? `${baseUrl}${user.photo}` : null;
  

  console.log("header images profile", profileImageUrl)
  return (
    <HeaderAppBar position="static">
      <HeaderMobileDrawer>
        <IconButton onClick={() => toggleDrawer(!isOpen)}>
          <MenuIcon />
        </IconButton>
        <LogoIcon />
      </HeaderMobileDrawer>
      <HeaderButton
        variant="text"
        endIcon={<ArrowDownIcon />}
        onClick={(event) => {
          setAnchorMenu(event.currentTarget);
          setShowAccountMenu(true);
        }}
      >
        <HeaderAvatar alt={`${GetUserDisplayName(user)}`} src={profileImageUrl} />
        {GetUserDisplayName(user)}
      </HeaderButton>
      <Menu
        anchorEl={anchorMenu}
        open={showAccountMenu}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={handleMenuClose}
          component={Link}
          to={RoutesEnum.Profile}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={handleMenuClose}
          component={Link}
          to={RoutesEnum.Settings}
        >
          Client Info
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <HeaderSwitchContainer>
        <ThemeToggler checked={checked} onChange={handleChange} />
      </HeaderSwitchContainer>
    </HeaderAppBar>
  );
};
