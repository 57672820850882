import api from "client/utils/api-client";

import { TransactionsDataType } from "../balance/types/transactions-data.type";
import {
  BillingInfoParams,
  PaymentMethodParams,
} from "./types/billing-info.type";
import { CreateOrderParams } from "./types/create-order-params.type";
import { PaymentSettings } from "./types/payment-settings.type";

export const order = {
  create: (params: CreateOrderParams): Promise<string> =>
    api.post(`/orders/pay`, params),
};

export const methods = {
  create: (params: Partial<BillingInfoParams>): Promise<BillingInfoParams> =>
    api.post(`/payment/methods`, params),
  get: (params: Partial<BillingInfoParams>): Promise<PaymentMethodParams> =>
    api.get(`/payment/methods`, { params }),
  delete: (params: Partial<BillingInfoParams>): Promise<PaymentMethodParams> =>
    api.delete(`/payment/methods`, { data: params }),
};

export const settings = {
  update: (params: Partial<PaymentSettings>): Promise<PaymentSettings> =>
    api.post(`/payment/settings`, params),
};

export const transactions = {
  get: (): Promise<TransactionsDataType[]> => api.get(`/payment/transactions`),
};
