import { useCallback, useState } from 'react';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import * as api from 'client/services/payment';
import { CreateOrderParams } from 'client/services/payment/types/create-order-params.type';

type UsePaymentType = {
  loading: boolean;
  createPayment: (params: CreateOrderParams) => Promise<string>;
  proceedPayment: (
    secret: string | undefined,
    methodId?: string | undefined,
    tokenId?: string,
  ) => Promise<void>;
};

export const usePayment = (): UsePaymentType => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState<boolean>(false);

  const createPayment = useCallback((params: CreateOrderParams) => {
    console.log('Params being sent to API:', params);
    setLoading(true);
    return api.order.create(params).finally(() => setLoading(false));
  }, []);

  const proceedPayment = useCallback(
    async (
      secret: string | undefined,
      methodId?: string | undefined,
      tokenId?: string,
    ) => {
      if (!stripe || !secret) {
        setLoading(false);
        return Promise.reject('Payment Error');
      }
  
      setLoading(true);
  
      try {
        let paymentMethod;
  
        if (methodId) {
          paymentMethod = methodId;
        } else if (tokenId) {
          paymentMethod = {
            card: { token: tokenId },
          };
          console.log("Using tokenId, skipping additional confirmation.");
          setLoading(false);
          return;
        } else {
          if (!elements) {
            setLoading(false);
            return Promise.reject('Payment Error');
          }
          const cardElement = elements.getElement(CardElement);
          if (!cardElement) {
            setLoading(false);
            return Promise.reject('Payment Error');
          }
  
          const { paymentMethod: newPaymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
          });
  
          if (error) {
            setLoading(false);
            return Promise.reject(error.message);
          }
          paymentMethod = newPaymentMethod?.id;
        }
        console.log("Payment method set:", paymentMethod);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        setLoading(false);
      }
    },
    [stripe, elements],
  );

  return {
    loading,
    createPayment,
    proceedPayment,
  };
};

export default usePayment;
