import React from 'react';

import { Box } from '@mui/material';

import useNotification from 'client/hooks/useNotification';

import { usePayment } from '../../pages/Checkout/hooks/usePayment';
import PaymentCardInput from './components/PaymentCardInput/PaymentCardInput';
import { PaymentCardButton } from './PaymentCard.styled';

type PaymentCardProps = {
  balanceAdd?: boolean;
  secret: string | undefined;
  onSuccess: () => void;
};

export const PaymentCard: React.FC<PaymentCardProps> = ({
  balanceAdd,
  secret,
  onSuccess,
}) => {
  const { setError } = useNotification();
  const { loading, proceedPayment } = usePayment();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    proceedPayment(secret)
      .then(onSuccess)
      .catch((error) => setError(error));
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <PaymentCardInput />
      <PaymentCardButton balanceAdd={balanceAdd} loading={loading} />
    </Box>
  );
};

export default PaymentCard;
